import React from 'react';
import styled from 'styled-components'
import {ProjectLinks} from "./ProjectLinks";

const StyledContent = styled.div`
  margin-top: 120px;
  
  & > h2 {
    margin-bottom: 50px;
  }

  & > div {
    margin-bottom: 30px;
  }
`

export const Content = () => (
  <StyledContent>
    <h2>Mina projekt</h2>
    <ProjectLinks />
  </StyledContent>
)