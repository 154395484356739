import React from 'react';
import styled from "styled-components";

const StyledLink = styled.div`
  transition: transform .2s;
  
  &:hover {
    transform: scale(1.2);
  }
`

export const Link = ({href, text}) => (
  <StyledLink>
    <a href={href}>{text}</a>
  </StyledLink>
)