import React from 'react';
import styled from "styled-components";
import {LinkGroup} from "components/LinkGroup";

const StyledHeading = styled.h1`
  font-weight: lighter;
  font-style: italic;
`

const links = [
  { text: "Github", href: "https://github.com/machalvan/" },
  { text: "LinkedIn", href: "https://www.linkedin.com/in/hanssonmarcus/" },
]

export const Heading = () => (
  <>
    <StyledHeading>Marcus Hansson</StyledHeading>
    <LinkGroup group={links} />
  </>
)