import React from "react";
import styled from "styled-components";
import {Link} from "./Link";

const StyledLinkGroup = styled.div`
  display: inline-flex;
  
  & > div {
    margin: 8px 40px;
  }
`

export const LinkGroup = ({group}) => (
  <StyledLinkGroup>
    {group.map((link, index) => <Link key={`linkGroup__${index}`} {...link} />)}
  </StyledLinkGroup>
)