import React from "react";
import {LinkGroup} from "components/LinkGroup";
import {Link} from "components/Link";

const varExampleHref = `
https://tio.run/##lZJPT8JAEMXv/RQTTpqQIBdNvJFCIsYgEdSj2bYDbtju1P1Tw6evs6WtGlLBHtrDznvze/taClNVj89rGMy0QwN78gY2oiQjHYL2eY
LmFgYAoxEsjdQOrOPPFhyBe0fwFk00XyyD5o3Hoe9h/cqROShA6sI7fkMpjBSJQrg4@F62Rmy6Psc0Jl2icTVMg9aqGFFoXuJwi2YIcgMFWSt5W/QyeYKUfB
153G8@4fGt5gm26kgbXRS93j2cQzixoIixhO2mM0ILmhzghxcKroaANUoIsSGl6DPkSClDSBSluyhY1S3FYTkf1p0creptKJyv1t@Zz7jM5t46SbjNxrrDOW
F3wGmHSqE8HlGFmv9B1WRrFYlId797jhrX@M9@2XWuU4M5/gBM9jCu1dNZ3FXVo55iq24Ha/VsMYVTD6vvfV507CFWSsagLUhnIV34sVLKc6EzIA1KaoTrqr
r5Ag
`

const links = [
  [
    { text: "Programmeringsspråket VAR", href: "https://github.com/machalvan/VAR" },
    { text: "(Exempel)", href: varExampleHref },
  ],
  { text: "Adventskalender", href: "https://advent.netlify.com/" },
]

export const ProjectLinks = () => (
  links.map((group, index) => (
    Array.isArray(group)
      ? <LinkGroup key={`linkGroup__${index}`} group={group} />
      : <Link key={`link__${index}`} {...group} />
  ))
)