import React from 'react';
import styled from 'styled-components'
import {Content} from "partials/Content";
import {Heading} from "partials/Heading";

const StyledApp = styled.div`
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
  a {
    color: white;
  }
`

const App = () => {
  return (
    <StyledApp>
      <Heading />
      <Content />
    </StyledApp>
  );
}

export default App;
